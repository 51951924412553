// Imports
// ------
import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

// Styles
// ------
import {
	Gradient,
    Animation1,
    Animation2,
    Content,
} from './styles';

// Component
// ------
function BackgroundGradient({ children }) {

	return (
		<Gradient>
            <Content>
                {children}
            </Content>

            {/* <Animation1>
                <StaticImage src="../../images/gradient.png" alt="" sizes="18vw" loading="eager" />
            </Animation1>
            
            <Animation2>
                <StaticImage src="../../images/gradient.png" alt="" sizes="18vw" loading="eager" />
            </Animation2> */}
        </Gradient>
	);
}

export default BackgroundGradient;
