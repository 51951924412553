// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Gradient = styled.div(
	(props) => css`
		background: linear-gradient(200.05deg, #111012 15.73%, #000000 83.78%);
		position: relative;
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;
		z-index: 1;
	`
);

export const Animation1 = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		animation: up-and-down ease-in-out 7s infinite;
		width: 55vw;
		opacity: 0.2;
		z-index: 0;

		${breakup.medium`
			width: 18vw;
			opacity: 0.5;
        `}

		@keyframes up-and-down {
			0% {
				transform: translateY(0);
			}

			50% {
				transform: translateY(5rem);
			}

			100% {
				transform: translateY(0); 
			}
		}
	`
);

export const Animation2 = styled.div(
	(props) => css`
		position: absolute;
		width: 18vw;
		top: 100vh;
		right: 0;
		animation: up-and-down-2 ease-in-out 7s infinite;
		opacity: 0.3;
		z-index: 0;

		${breakup.medium`
			width: 18vw;
			opacity: 0.5;
			top: calc(40vh + 17rem);
        `}

		@keyframes up-and-down-2 {
			0% {
				transform: translateY(0) rotate(180deg);
			}

			50% {
				transform: translateY(5rem) rotate(180deg);
			}

			100% {
				transform: translateY(0) rotate(180deg); 
			}
		}	`
);