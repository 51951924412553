// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1, H2, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		padding: 11rem 0 4rem 0;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-bottom: 1px solid #131416;

		${breakup.medium`
			padding-top: 18rem;
			padding-bottom: 12rem;
		`}

		${breakup.large`
			padding-top: 24rem;
		`}
	`
);

export const Hero = styled('div')(
	(props) => css`
		max-width: 66rem;
		text-align: center;
		margin: 0 auto;
		flex: 1;
		z-index: 2;

		a {
			margin-top: 4rem;
		}
	`
);

export const Title = styled(H1)((props) => css`
	max-width: 9em;
	letter-spacing: -2px;
	font-size: 4rem;

	${breakup.large`
		max-width: 8em;
		line-height: 0.858em;
		font-size: clamp(5rem, 4.8vw, 7rem);
		
	`}
	
	margin: 0 auto 0.4em auto;
`);

export const EmbedAndToggles = styled.div((props) => css`
	flex: 1.2;
`);

export const ConditionalRows = styled.div((props) => css`
	display: flex;
	flex-direction: column;
	gap: 4rem;

	${breakup.large`
		&.row-style-active {
			flex-direction: row;
			margin: 0 auto;
			max-width: 130rem;
			width: 100%;
			align-items: center;

			${breakup.xhuge`
                max-width: 165rem;
            `}

			& > div {
				text-align: left;
			}

			.hero {
				margin-top: 0;
			}

			h1 {
				margin: 0 0 0.4em 0;
			}

			.label {
				margin-left: 0;
				background-color: transparent !important;
				padding: 0;
				margin-bottom: 5rem;
				
				span {
					font-size: 1.6rem;
				}
			}
		}
	`}
`);

export const Excerpt = styled(P)(
	(props) =>
		css`
			font-weight: ${props.theme.light};
			letter-spacing: -1px;
			font-size: 1.6rem;
			max-width: 90%;
			margin-left: auto;
			margin-right: auto;

			${breakup.medium`
				font-size: 2.2rem;
				max-width: 23em;

				&.row-style-active {
					margin-left: 0;
				}
			`}
		`
);

export const EmbedWrapper = styled.div(
	(props) => css`
		${breakup.medium`
			position: relative;
		`}

		${breakup.large`
			max-width: 96rem;
			width: 100%;
			margin: 0 auto 1.5rem auto;
		`}
	`
);

export const Embed = styled.div(
	(props) => css`
		position: relative;

		iframe,
		.gatsby-image-wrapper {
			width: 100%;
			aspect-ratio: 963 / 560;
			border-radius: 0.9rem;
		}
	`
);

export const Info = styled.div(
	(props) => css`
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #080809;
		padding: 2rem 3rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		z-index: 99;
		width: 100%;
		height: 100%;
		gap: 0 1.5rem;
		animation: fadein 0.3s linear;

		@keyframes fadein {
			from {
				opacity: 0;
			}

			to {
				opacity: 1;
			}
		}

		${breakup.medium`
			padding: 7rem 8rem;
			border-radius: 0.9rem;
		`}

		${breakup.medium`
			&:not(.row-style-active) {
				position: absolute;
				width: calc(100% - 2.2rem);
				height: calc(100% - 2.2rem);
				z-index: 10;
			}
		`}

		${breakup.medium`
			&.row-style-active {
				padding: 2.6rem 5.8rem;
				width: 100%;
				height: 100%;
				position: absolute;
				width: calc(100% - 2.2rem);
				height: calc(100% - 2.2rem);
				z-index: 10;

				h2 {
					color: #292A2C;
					font-size: 2.4rem;

					${breakup.xhuge`
						font-size: 2.8rem;
						margin-top: -10rem;
					`}
				}

				p {
					font-size: 1.4rem !important;
					line-height: 1.45em;

					b {
						font-size: 1.6rem !important;
						margin-top: 2rem !important;
						font-weight: 400 !important;
					}
				}
			}
		`}

		@media (min-width: 1024px) and (max-width: 1100px) {
			&.row-style-active {
				padding: 1rem 1rem;

				p {
					font-size: 1.3rem !important;

					b {
						margin-top: 1rem !important;
					}

					&:first-of-type b {
						margin-top: 0 !important;
					}
				}
			}
		}

		@media (min-width: 1100px) and (max-width: 1250px) {
			&.row-style-active {
				padding: 3rem;

				p {
					font-size: 1.3rem !important;

					b {
						margin-top: 1rem !important;
					}

					&:first-of-type b {
						margin-top: 0 !important;
					}
				}
			}
		}
	`
);

export const InfoTitle = styled(H2)(
	(props) => css`
		font-size: 3rem;
		width: 100%;
		display: flex;
		align-items: flex-end;

		@media (min-width: 1024px) and (max-width: 1310px) {
			display: none;
		}
	`
);

export const InfoColumn = styled.div(
	(props) => css`
		width: min(27rem, 46%);

		p {
			color: #929292;
			font-weight: 300;
			font-size: 1.6rem !important;
			font-family: 'Kanit';
			margin: 0 !important;
		}
	`
);

export const InfoLabel = styled.p(
	(props) => css`
		b {
			display: block;
			margin-top: 2.7rem !important;
			color: #fff !important;
			font-weight: 500 !important;
			font-size: 1.8rem !important;
		}
	`
);

export const DownloadButton = styled.a(
	(props) => css`
		font-family: 'Kanit';
		position: absolute;
		bottom: 3.1rem;
		right: 3.1rem;
		color: #3F1FFF !important;
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-weight: 300;

		&.row-style-active {
			font-size: 1.4rem;
			bottom: 1.3rem;
			right: 1.3rem;
		}

		&:hover {
			text-decoration: underline;
		}
	`
);

export const OpenInfo = styled.button(
	(props) => css`
		padding: 1.35rem;
		position: absolute;
		cursor: pointer;
		background-color: unset;
		border: unset;
		top: 0;
		right: 0;
		background-color: #3f1efd;
		transition: filter 0.2s;
		border-radius: 0 1rem 0 1rem;

		&:hover {
			filter: brightness(150%);
		}

		img {
			width: 1.35rem;
			height: 1.35rem;
			object-position: center;
		}
	`
);

export const CloseIcon = styled.button(
	(props) => css`
		padding: 1.5rem;
		position: absolute;
		top: 9.5rem;
		right: 1rem;
		cursor: pointer;
		background-color: unset;
		border: unset;

		@media only screen and (min-width: 768px) {
			top: 1rem;
		}
	`
);

export const Toggles = styled.div(
	(props) => css`
		display: flex;
		gap: 1rem;
		max-width: 96rem;
		width: 100%;
		margin: 1rem auto 2rem auto;

		${breakup.large`
			margin-top: 0;
		`}
	`
);

export const Toggle = styled.button(
	(props) => css`
		flex: 1;
		background-color: #131416;
		padding: 1.2rem;
		font-family: 'Kanit';
		font-weight: 400;
		font-size: 1rem;
		border-radius: 0.5rem;
		cursor: pointer;
		transition: all 0.2s;
		letter-spacing: 0.05em;
		position: relative;
		display: flex;
		align-items: center;
		gap: 0;
		justify-content: center;

		span {
			width: 0;
			transition: all 0.4s;
			opacity: 0;
			overflow: hidden;
			white-space: nowrap;
		}

		&::after {
			content: '';
			width: 100%;
			height: 5px;
			border-radius: 7px 7px 0 0;
			position: absolute;
			left: 0;
			bottom: -32px;
			background: linear-gradient(90deg, rgba(108,83,255,1) 0%, rgba(50,21,203,1) 100%);
			transition: all 0.3s;
			transition-delay: 0.1s;
			opacity: 0;
		}

		&.active {
			background-color: #000000 !important;
			flex: 1.5;
			gap: 0.0.35rem;

			${breakup.medium`
				gap: 1rem;
			`}

			span {
				width: 3.6em;
				opacity: 1;
			}

			&::after {
				bottom: -17px;
				opacity: 1;
			}
		}

		&:hover {
			background-color: #1c1c1c;
		}

		${breakup.large`
			font-size: 1.8rem;
		`}
	`
);

export const Animation1 = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		animation: up-and-down ease-in-out 7s infinite;
		width: 55vw;
		z-index: 0;
		opacity: 0.2;
		user-select: none;
		pointer-events: none;
		
		img {
			transform: rotate(180deg);
		}

		${breakup.medium`
			width: 18vw;
			opacity: 1;
        `}

		@keyframes up-and-down {
			0% {
				transform: translateY(0);
			}

			50% {
				transform: translateY(-7rem);
			}

			100% {
				transform: translateY(0); 
			}
		}
	`
);

export const Animation2 = styled.div(
	(props) => css`
		position: absolute;
		width: 18vw;
		bottom: 0;
		right: 0;
		animation: up-and-down-2 ease-in-out 7s infinite;
		z-index: 0;
		opacity: 0.2;
		display: flex;
		justify-content: flex-end;
		user-select: none;
		pointer-events: none;

		${breakup.medium`
			width: 18vw;
			opacity: 1;
        `}

		@keyframes up-and-down-2 {
			0% {
				transform: translateY(0);
			}

			50% {
				transform: translateY(7rem);
			}

			100% {
				transform: translateY(0); 
			}
		}	`
);