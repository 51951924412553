// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import Hero from '@parts/Hero/Home';
import TextAndImage from '@parts/TextAndImage';
import Cards from '@parts/Cards';
import LogoList from '@parts/LogoList';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import PageTransition from '@parts/PageTransition';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import BackgroundGradient from '@parts/BackgroundGradient';

// Component
// ------
function Index({ transitionStatus, entry, exit, data }) {
	const {
		subTitle,
		excerpt,
		titleDesktop,
		titleMobile,
		buttonLabel,
		buttonLink,
		image,
		logos,
		seo,
		assets,
		text,
		cards,
		useRowStyle,
	} = data.home;

	const ts = transitionStatus;
	const bp = useBreakpoint();

	useEffect(() => {
		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo data={seo} backupData={data.globalSeo.seo} />

			<PageTransition status={ts} entry={entry} exit={exit}>
				<BackgroundGradient>
					<Hero
						subTitle={subTitle}
						excerpt={excerpt}
						title={[titleDesktop, titleMobile]}
						image={image}
						logos={logos}
						assets={assets}
						buttonLabel={buttonLabel}
						buttonLink={buttonLink}
						useRowStyle
					/>

					<LogoList logos={logos} />

					<Cards
						cards={cards}
					/>

					<TextAndImage
						text={text}
						image={image}
						buttonLabel={buttonLabel}
						buttonLink={buttonLink}
					/>

					<Newsletter />
				</BackgroundGradient>
				<Footer />
			</PageTransition>
		</>
	);
}

export default Index;

// GraphQL
// ------
export const query = graphql`
	query Home {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
		home: datoCmsHome {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			subTitle
			titleDesktop: pageTitle
			titleMobile: pageTitleMobile
			excerpt
			buttonLabel
			useRowStyle
			buttonLink
			text
			image {
				gatsbyImageData(
					width: 1020
					placeholder: BLURRED
					forceBlurhash: false
					layout: FULL_WIDTH
					backgroundColor: ""
				)
				alt
				smartTags
			}

			video {
				url
			}

			assets {
				name
				captureDevice
				asset
				downloadLink
				embedId
				engine
				ofImages
				pipeline
				productionTime
				assetInfo
				marsoMaterialMaps
				image {
					gatsbyImageData(
						width: 1020
						placeholder: BLURRED
						forceBlurhash: false
						layout: FULL_WIDTH
						backgroundColor: ""
					)
					alt
				}
			}

			cards {
				text
			}

			logos: backgroundLogos {
				gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
				url
				alt
			}
		}
	}
`;
