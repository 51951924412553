// Imports
// ------
import React from 'react';
import { observer } from 'mobx-react';
import HTMLParser from '@parts/HTMLParser';

// Styles
// ------
import {
	Jacket,
    Card,
} from './styles';

// Component
// ------
function HomeHero({ cards }) {

	return (
        <Jacket className={cards?.length > 3 ? 'cards-4' : ''}>
            {cards?.map((loop, i) => (
                <Card key={i}>
                    <HTMLParser toParse={loop.text} />
                </Card>
            ))}
		</Jacket>
	);
}

export default observer(HomeHero);
