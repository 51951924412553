// Imports
// ------
import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { observer } from 'mobx-react';
import HTMLParser from '@parts/HTMLParser';

// Styles
// ------
import {
	Jacket,
    Text,
	Image,
    Flex,
} from './styles';
import { White } from '../Button/styles';

// Component
// ------
function HomeHero({ text, image, buttonLabel, buttonLink }) {

	const animation = useRef(null);

	function hover() {
		if (!animation.current.classList.contains('animating')) {
			animation.current.classList.add('animating');

			setTimeout(() => {
				animation.current.classList.remove('animating');

				if (animation.current.matches(':hover')) {
					hover();
				}
			}, 6000);
		}
	}

	return (
		<Jacket onMouseEnter={() => hover()} ref={animation}>
			<Flex>
				<Text>
                    <HTMLParser toParse={text} />
					<White href={buttonLink} target="_blank" rel="noopener noreferrer">
						<span>{buttonLabel}</span>
					</White>

					<img className="animation-1" src="/gradients/gradient-right.png" alt="" loading="lazy" width="311" height="919" />
					<img className="animation-2" src="/gradients/gradient-right.png" alt="" loading="lazy" width="311" height="919" />
				</Text>

                <Image>
                    <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
                </Image>
			</Flex>
		</Jacket>
	);
}

export default observer(HomeHero);
