// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1, H2, P } from '@tackl';

// Exports
// ------
export const LogosList = styled.ul(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		gap: clamp(2rem, 4vw, 6rem);
		margin: 3rem auto;

		${breakup.medium`
			margin: 8rem auto;
		`}
	`
);

export const ButtonLogos = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		${breakup.large`
			flex-flow: row;
			justify-content: space-between;
			align-items: center;
		`}
	`
);

export const Logo = styled.li(
	(props) => css`
		display: inline-flex;
		width: auto;
		max-width: 14rem;
		height: auto;
		max-height: 2rem;

		${breakup.medium`
			max-height: 6rem;
		`}

		&:last-child {
			margin: 0;
		}

		&:nth-child(3) {
			max-width: 10rem;
		}

		img {
			width: 100%;
			max-width: 14rem;
			min-width: 6rem;

			height: auto;
			min-height: 3rem;
			max-height: 6rem;
		}
	`
);