// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1, H2, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
        max-width: 130rem;
        margin: 2rem auto 10rem auto;
        background-color: #060606;
        overflow: hidden;
        border-radius: 1rem;

        @media only screen and (min-width: 1800px) {
            max-width: 165rem;
        }

        .animation-1 {
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1.2) rotate(180deg) translateY(-8%);
            height: 100%;
            display: none;

            ${breakup.medium`
                display: block;
            `}

            @keyframes animation-1 {
                0% {
                    transform: scale(1.2) rotate(180deg) translateY(-8%);
                }
                50% {
                    transform: scale(2.2) rotate(180deg) translateY(-27%);
                }
                100% {
                    transform: scale(1.2) rotate(180deg) translateY(-8%);
                }
            }
        }

        .animation-2 {
            position: absolute;
            top: 0;
            right: -20%;
            transform: scale(1.2) translateY(-8%);
            height: 100%;
            display: none;
            
            ${breakup.medium`
                display: block;
            `}

            @keyframes animation-2 {
                0% {
                    transform: scale(1.2) translateY(-8%);
                }
                50% {
                    transform: scale(2.2) translateY(-27%);
                }
                100% {
                    transform: scale(1.2) translateY(-8%);
                }
            }
        }

        &.animating {
            .animation-1 {
                animation: animation-1 6s infinite ease-in-out;
            }
            .animation-2 {
                animation: animation-2 6s infinite ease-in-out;
            }
        }

        ${breakup.medium`
			width: 94%;
		`}
	`
);

export const Flex = styled.div(
	(props) => css`
        display: flex;
        gap: 2rem 0;
        flex-direction: column-reverse;
        padding: 3rem 2rem;
        position: relative;
        overflow: hidden;
        margin-bottom: -2px;

        ${breakup.medium`
			flex-direction: row;
            padding: 0;
            border-radius: 1rem;
            box-shadow: 0px 0px 5px transparent;
            transition: all 0.2s;

            &:hover {
                box-shadow: 0px 0px 5px #131416;

                &::after {
                    top: 0;
                    left: 0;
                    transform: scale(1);
                }
            }
		`}
	`
);

export const Text = styled.div(
	(props) => css`
        flex: 1;
        z-index: 1;
        position: relative;

        ${breakup.medium`
			padding: 8rem 1rem 8rem 8rem;
		`}

        h2, p, a, button {
            z-index: 1;
            position: relative;
        }

        h2 {
            margin-bottom: 0.6em;
            line-height: 0.9em;
            font-size: clamp(3.2rem, 5.5vw, 5.5rem);
        }

        p {
            margin: 1.5em 0;
            font-size: clamp(1.6rem, 4vw, 2.2rem);
            font-weight: 300;
        }
	`
);

export const Image = styled.div(
	(props) => css`
        flex: 1.3;
        position: relative;
        z-index: 1;

        ${breakup.medium`
			clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
            margin-bottom: -4px;
		`}

        .gatsby-image-wrapper {
            height: 100%;
        }

        img {
            border-radius: 1rem;
            object-position: left;
            display: block;
        }
	`
);