// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
	ButtonLogos,
	LogosList,
	Logo,
} from './styles';

// Component
// ------
function LogoList({ logos }) {

	return (
        <Row isExpanded sidePad>
            <Column small={12} mpad>
                <ButtonLogos>
                    <LogosList>
                        {logos.map((i, key) => (
                            <Logo key={key}>
                                <img src={i.url} alt={i.alt ? i.alt : 'Logo'} />
                            </Logo>
                        ))}
                    </LogosList>
                </ButtonLogos>
            </Column>
        </Row>
	);
}

export default LogoList;
