// Imports
// ------
import React, { useState } from 'react';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { observer } from 'mobx-react';
import HTMLParser from '@parts/HTMLParser';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	Hero,
	Title,
	Excerpt,
	Embed,
	EmbedWrapper,
	Toggles,
	Toggle,
	Info,
	InfoTitle,
	InfoColumn,
	InfoLabel,
	DownloadButton,
	CloseIcon,
	OpenInfo,
	ConditionalRows,
	EmbedAndToggles,
	Animation1,
    Animation2,
} from './styles';
import { Label, GradientText } from '@tackl';
import { White } from '../../Button/styles';

// Component
// ------
function HomeHero({ subTitle, title, excerpt, logos, assets, useRowStyle, buttonLabel, buttonLink }) {
	const bp = useBreakpoint();

	const [activeAsset, setActiveAsset] = useState(assets[0]);
	const [activeLabel, setActiveLabel] = useState(0);
	const [infoOpen, setInfoOpen] = useState(false);

	return (
		<Jacket>
			<Animation1>
                <img src="/gradients/gradient-right.png" alt="" loading="eager" width="311" height="919" />
            </Animation1>
            
            <Animation2>
                <img src="/gradients/gradient-right.png" alt="" loading="eager" width="311" height="919" />
            </Animation2>

			<Row isExpanded className="" sidePad>
				<Column>
					<ConditionalRows className={useRowStyle ? 'row-style-active' : ''}>

						<Hero className="hero">
							{subTitle &&
								<Label className="label" style={{background: '#000000'}}>
									<GradientText data-name={subTitle}>
										{subTitle}
									</GradientText>
								</Label>
							}
							<Title>{bp.medium ? title[1] : title[0]}</Title>
							<Excerpt className={useRowStyle ? 'row-style-active' : ''}>{excerpt}</Excerpt>
							<White href={buttonLink} target="_blank" rel="noopener noreferrer">
								<span>{buttonLabel}</span>
							</White>
						</Hero>

						<EmbedAndToggles>
							<EmbedWrapper>
								<Embed>
									{activeAsset?.embedId ?
										<iframe
											title="Asset embed"
											frameborder="0"
											allowfullscreen
											mozallowfullscreen="true"
											webkitallowfullscreen="true"
											allow="autoplay; fullscreen; xr-spatial-tracking"
											xr-spatial-tracking
											execution-while-out-of-viewport
											execution-while-not-rendered
											web-share
											src={`https://sketchfab.com/models/${activeAsset.embedId}/embed?autostart=1&ui_infos=0&ui_watermark_link=0&ui_watermark=0&ui_theme=dark&dnt=1`}
										/>
										:
										<GatsbyImage image={activeAsset.image.gatsbyImageData} alt={activeAsset.image?.gatsbyImageData} />
									}

									<OpenInfo onClick={() => setInfoOpen(true)}>
										<img src="/icons/info.svg" alt="" width="36" height="36" loading="lazy" />
									</OpenInfo>
								</Embed>
								{infoOpen &&
									<Info className={useRowStyle ? 'row-style-active' : ''}>
										<InfoTitle>Asset Production Report</InfoTitle>

										<InfoColumn>
											<InfoLabel><b>Asset</b></InfoLabel>
											<HTMLParser toParse={activeAsset.asset} />

											<InfoLabel><b>Capture Device</b></InfoLabel>
											<HTMLParser toParse={activeAsset.captureDevice} />

											<InfoLabel><b>Pipeline</b></InfoLabel>
											<HTMLParser toParse={activeAsset.pipeline} />

											<InfoLabel><b>Marso Material Maps</b></InfoLabel>
											<HTMLParser toParse={activeAsset.marsoMaterialMaps} />
										</InfoColumn>

										<InfoColumn>
											<InfoLabel><b># of images</b></InfoLabel>
											<HTMLParser toParse={activeAsset.ofImages} />

											<InfoLabel><b>Asset Info</b></InfoLabel>
											<HTMLParser toParse={activeAsset.assetInfo} />

											<InfoLabel><b>Engine</b></InfoLabel>
											<HTMLParser toParse={activeAsset.engine} />

											<InfoLabel><b>Production Time</b></InfoLabel>
											<HTMLParser toParse={activeAsset.productionTime} />
										</InfoColumn>

										<DownloadButton
											href={activeAsset.downloadLink}
											target="_blank"
											rel="noopener noreferrer"
											download
											className={useRowStyle ? 'row-style-active' : ''}
										>
											Download 3D File
											<img src="/icons/download.svg" alt="" width="34" height="34" loading="lazy" />
										</DownloadButton>

										<CloseIcon onClick={() => setInfoOpen(false)}>
											<img src="/icons/close-icon.svg" alt="" width="15" height="15" loading="lazy" />
										</CloseIcon>
									</Info>
								}
							</EmbedWrapper>

							<Toggles>
								{assets?.map((loop, i) => (
									<Toggle
										key={i}
										onClick={() => { setActiveAsset(assets[i]); setActiveLabel(i) }}
										className={activeLabel === i ? 'active' : ''}
									>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="#3F1FFF" fill-opacity="0.84"/>
											<path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="white" stroke-width="1.2" stroke-linejoin="round"/>
										</svg>
										<span>{loop.name}</span>
									</Toggle>
								))}
							</Toggles>
						</EmbedAndToggles>

					</ConditionalRows>
				</Column>
			</Row>
		</Jacket>
	);
}

export default observer(HomeHero);
