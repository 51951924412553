// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
        max-width: 96.5rem;
        width: 94%;
        margin: 2rem auto 7rem auto;
        gap: 2rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &.cards-4 {
            max-width: 130rem;
            display: grid;
            grid-template-columns: 1fr;

            ${breakup.xhuge`
                max-width: 165rem;
            `}

            ${breakup.medium`
                grid-template-columns: 1fr 1fr;
            `}

            ${breakup.large`
                grid-template-columns: 1fr 1fr 1fr 1fr;
            `}
        }

        ${breakup.medium`
            flex-direction: row;
        `}

        ${breakup.large`
            gap: 3.2rem;
            margin-top: -1rem;
        `}
	`
);

export const Card = styled.div(
	(props) => css`
        background-color: #131416;
        padding: 3rem 3.6rem;
        flex: 1;
        border-radius: 1rem;

        h2 {
            font-size: 3.2rem;
            font-weight: 400;
            max-width: 6em;
            margin-bottom: 1.25rem;
        }

        p {
            font-size: 1.8rem;
            font-weight: 300;
            line-height: 1.35em;
            letter-spacing: -1px;
        }
	`
);

